import React, { FC } from "react";

import Layout from "../layout/layout";
import { MetaData } from "../layout/metadata";
import { Link } from "gatsby";

const NotFoundPage: FC = () => (
  <Layout>
    <MetaData title="404: Not Found" />
    <div className="notfound-wrapper">
      <div className="notfound-content">
        <h2>404 &ndash; Not Found</h2>
        <p>
          Looks like you got lost.
          <br />
          Let's get you back to the <Link to="/">homepage</Link>!
        </p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
